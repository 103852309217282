import React, { useState } from 'react'


const SearchBar = ({ onSubmit }) => {
    const [term, setTerm] = useState('')

    const onFormSubmit = (event) => {
        event.preventDefault()
        onSubmit(term)
    }

    return (
        <div className="ui segment search-bar">
            <form className="ui form" onSubmit={onFormSubmit}>
                <div className="field">
                    <label>Youtube Video Search</label>
                    <input type="text" placeholder="Search for a video" value={term} onChange={(e) => setTerm(e.target.value)} />
                </div>
            </form>
        </div>
    )
}

export default SearchBar
