import React, { useEffect, useState } from 'react'
import SearchBar from './components/SearchBar'
import VideoList from './components/VideoList'
import VideoDetail from './components/VideoDetail'
import useVideos from './hooks/useVideos'


const App = () => {
    const [selectedVideo, setSelectedVideo] = useState(null)
    const [videos, searchVideos] = useVideos('outlier ultrafine merino')

    useEffect(() => {
        setSelectedVideo(videos[0])
    }, [videos])

    return (
        <div className='ui container'>
            <SearchBar onSubmit={searchVideos} />
            <div className="ui stackable grid">
                <div className="ui row">
                    <div className="eleven wide column">
                        <VideoDetail video={selectedVideo} />
                    </div>
                    <div className="five wide column">
                        <VideoList onVideoSelect={setSelectedVideo} videos={videos} />
                    </div>
                </div>
            </div>
        </div>
    )
}


export default App
