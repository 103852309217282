
import axios from 'axios'

const KEY = 'AIzaSyDCracs9ukuNPc6yyBDk5B0r6EPzr-W8hQ'

export default axios.create({
    baseURL: 'https://www.googleapis.com/youtube/v3',
    params: {
        key: KEY
    }
})
